<script lang='ts' setup>
import { notification } from 'ant-design-vue'
import { useAclStore } from '../store/acl'
import Footer from '~/components/layouts/footer.vue'
import Nav from '~/components/layouts/nav.vue'
import { useAlertStore } from '~/store/alert'

const $route = useRoute()
const $acl = useAclStore()
const $alert = useAlertStore()
let lastError = ''
let lastSuccess = ''

watch(() => $alert?.error, (msg: string) => {
  if ($alert.error && lastError !== $alert.error) {
    notification.open({
      message: 'Error',
      description:
        $alert.error,
    })
  }
  lastError = $alert.error
})

watch(() => $alert.success, (msg: string) => {
  if ($alert.success && lastSuccess !== $alert.success) {
    notification.open({
      message: 'Success',
      description:
        $alert.success,
    })
  }
  lastSuccess = $alert.success
})

onMounted(() => {
  $acl.checkSession()
})

const $nav = useNavStore()
// useHead({
//   title: computed(() => $nav.title ? `${$nav.title} - Cel-Elements` : 'Cel-Elements'),
// })

const computedTitle = computed(() => $nav.title || $route.meta.title ? `${$nav.title || $route.meta.title} - Cel-Elements` : 'Cel-Elements')
useHead({
  title: computedTitle,
  meta: [
    { property: 'og:title', content: computedTitle }
  ]
})
</script>

<template>
  <a-layout theme="light">
    <Nav />
    <a-layout-content>
      <a-alert
        v-if="$alert.error" :message="$alert.error" type="error" class="fixed top-0 z-10 w-full layout-alert"
        closable
        @close="$alert.clearError"
      />
      <a-alert
        v-if="$alert.success" :message="$alert.success" type="success" class="fixed top-0 z-10 w-full layout-alert"
        closable
        @close="$alert.clearSuccess"
      />
      <div v-if="$alert.error" class="h-10"></div>
      <slot />
    </a-layout-content>
    <Footer />
  </a-layout>
</template>

<style scoped>
.layout-alert {
  max-width: 1190px;
}
</style>
